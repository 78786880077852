<template>
  <ui-component-modal
    modalTitle="Delete subscription"
    buttonLabelConfirm="Delete"
    confirmButtonCssClass="is-danger"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteSubscription"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="has-margin-bottom is-size-5 has-text-centered">
        Are you sure you want to delete this subscription?
      </div>
      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isDeleteConfirmed"
          class="has-text-left"
          :label="'Yes, I am sure!'"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import subscriptionProvider from '@/providers/subscription'

const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    subscription: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
      mSubscription: null,
    }
  },

  computed: {},

  created() {
    this.mSubscription = JSON.parse(JSON.stringify(this.subscription))
  },

  methods: {
    deleteSubscription() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        subscriptionProvider.methods
          .deleteSubscription(this.mSubscription.Id)
          .then((response) => {
            if (response.status === 204) {
              self.isDeletingSuccess = true

              self.$emit('subscriptionDeleted', this.mSubscription)

              self.onClickCancel()
            }
          })
          .catch((err) => {})
      }
    },
  },
}
</script>
